// About.js
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Button, Form, Input, Modal} from 'antd';
import {supabase,} from "./supabase";

const Login = () => {
    const [modal, contextHolder] = Modal.useModal();
    const [form] = Form.useForm();


    const navigate = useNavigate();
    const handleSubmit = async () => {
        const {data, error} = await supabase.auth.signInWithPassword(form.getFieldsValue());
        if (error) {
            console.log(error)
            modal.error({
                title: error.message,
                content: error.hint,
            });
            return
        }
        navigate('/applications');
    };

    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
            <div style={{textAlign: 'center'}}>
                <Form layout='vertical' form={form}>
                    <Form.Item name='email' label='email'>
                        <Input/>
                    </Form.Item>
                    <Form.Item name='password' label='password'>
                        <Input/>
                    </Form.Item>
                </Form>
                <Button type='primary' onClick={handleSubmit}>提交</Button>


            </div>
            {contextHolder}
        </div>
    );
}

export default Login;
